import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import OrderForm from "./components/OrderForm";
const GlobalStyle = () => (
  <Global
    styles={css`
      body {
        background: url("https://bitch.pizza/bg.png") no-repeat center center
          fixed;
        background-size: cover;
        background-color: #fcd447;
      }
      button {
        border: 2px solid black; /* Borde negro para todos los botones */
      }
    `}
  />
);

const theme = extendTheme({
  components: {
    Input: {
      baseStyle: {
        field: {
          backgroundColor: "white",
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          backgroundColor: "white",
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          backgroundColor: "white",
        },
      },
    },
  },
  styles: {
    global: {
      "html, body": {
        background: "#fcd447",
        color: "black",
        minHeight: "100vh",
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <GlobalStyle />
      <OrderForm />
    </ChakraProvider>
  );
}

export default App;
