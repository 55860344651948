import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Select,
  Radio,
  RadioGroup,
  Button,
  Stack,
  Center,
  Heading,
  Text,
  Image,
  Input,
  Tab,
  TabList,
  TabPanels,
  SimpleGrid,
  HStack,
  Icon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  CheckboxGroup,
  Checkbox,
  TabPanel,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { GiFullPizza } from "@react-icons/all-files/gi/GiFullPizza";
import { ReactComponent as EmpanadaIcon } from "./assets/EmpanadaIcon.svg"; // Asegúrate de tener el archivo en esta ubicación
import axios from "axios";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "./assets/custom-map.css";
import "leaflet/dist/leaflet.css";

// Icono personalizado para el marcador
const customIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const OrderForm = () => {
  const [productType, setProductType] = useState("");
  const [pizzaOrders, setPizzaOrders] = useState([]);
  const [empanadaOrders, setEmpanadaOrders] = useState([]);
  const [showDrinks, setShowDrinks] = useState(false);
  const [drinks, setDrinks] = useState([]);
  const pizzaRef = useRef([]);
  const empanadaRef = useRef([]);
  const [selectedEmpanadaTab, setSelectedEmpanadaTab] = useState(0);
  const [selectedPizzaTab, setSelectedPizzaTab] = useState(0);

  const [location, setLocation] = useState([0, 0]);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const toppingOptions = [
    "Panceta",
    "Jamón",
    "Cebolla",
    "Morrón",
    "Huevo",
    "Queso azul",
    "Ananá",
    "Aceitunas",
  ];

  const empanadaOptions = [
    "Gusto1",
    "Gusto2",
    "Gusto3",
    "Gusto4",
    "Gusto5",
    "Gusto6",
    "Gusto7",
    "Gusto8",
    "Gusto9",
    "Gusto10",
  ];

  const drinkOptions = ["Coca 1L", "Cerveza 1", "Cerveza 2"];

  // Inicializar las opciones de bebidas con cantidad 0
  useEffect(() => {
    const initialDrinks = drinkOptions.map((drink) => ({
      name: drink,
      quantity: 0,
    }));
    setDrinks(initialDrinks);
  }, []);

  const updateLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation([latitude, longitude]);
          setIsMapLoaded(true);
        },
        (error) => {
          console.error("Error obteniendo la ubicación: ", error);
        }
      );
    }
  };

  useEffect(() => {
    updateLocation();

    const handleFocus = () => {
      updateLocation();
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  const handleProductTypeChange = (type) => {
    setProductType(type);
  };

  const handleSelectChange = (e) => {
    setProductType(e.target.value);
  };

  const handlePizzaTypeChange = (index, value) => {
    const updatedPizzaOrders = [...pizzaOrders];
    updatedPizzaOrders[index].type = value;
    setPizzaOrders(updatedPizzaOrders);
  };

  const handlePizzaToppingsChange = (index, selectedToppings) => {
    if (selectedToppings.length <= 3) {
      const updatedPizzaOrders = [...pizzaOrders];
      updatedPizzaOrders[index].toppings = selectedToppings;
      setPizzaOrders(updatedPizzaOrders);
    }
  };

  const addPizzaOrder = () => {
    setPizzaOrders([...pizzaOrders, { type: "", toppings: [] }]);
    setSelectedPizzaTab(pizzaOrders.length); // Selecciona el último tab
    setTimeout(() => {
      const lastIndex = pizzaOrders.length;
      pizzaRef.current[lastIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 0);
  };

  const removePizzaOrder = (index) => {
    const updatedPizzaOrders = pizzaOrders.filter((_, i) => i !== index);
    setPizzaOrders(updatedPizzaOrders);
  };

  const handleEmpanadaChange = (index, value) => {
    const updatedEmpanadaOrders = [...empanadaOrders];
    updatedEmpanadaOrders[index] = value;
    setEmpanadaOrders(updatedEmpanadaOrders);
  };

  const addEmpanadaOrder = () => {
    setEmpanadaOrders([...empanadaOrders, ""]);
    setSelectedEmpanadaTab(empanadaOrders.length); // Selecciona el último tab
    setTimeout(() => {
      const lastIndex = empanadaOrders.length;
      empanadaRef.current[lastIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 0);
  };

  const removeEmpanadaOrder = (index) => {
    const updatedEmpanadaOrders = empanadaOrders.filter((_, i) => i !== index);
    setEmpanadaOrders(updatedEmpanadaOrders);
  };

  const handleDrinkQuantityChange = (index, quantity) => {
    const updatedDrinks = [...drinks];
    updatedDrinks[index].quantity = quantity;
    setDrinks(updatedDrinks);
  };

  const handleSubmit = () => {
    const order = {
      pizzas: pizzaOrders,
      empanadas: empanadaOrders,
      drinks: drinks.filter((drink) => drink.quantity > 0),
      location,
    };

    axios
      .post("http://tu-backend/api/pedidos", order)
      .then((response) => {
        console.log("Order received:", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Box p={4}>
      <Center>
        <VStack spacing={4}>
          <Image width="200px" src="https://bitch.pizza/pizzabitch.png" />
        </VStack>
      </Center>
      {isMapLoaded && (
        <Box mt={4} height="200px" width="100%" className="map-container">
          <MapContainer
            center={location}
            zoom={17}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
              position={location}
              icon={customIcon}
              className="custom-marker"
            />
          </MapContainer>
        </Box>
      )}
      <Center>
        <VStack>
          <HStack spacing={4} mt={2}>
            {pizzaOrders.length > 0 && (
              <HStack>
                <GiFullPizza />
                <Text>{pizzaOrders.length}</Text>
              </HStack>
            )}
            {empanadaOrders.length > 0 && (
              <HStack>
                <Icon as={EmpanadaIcon} w={6} h={6} />
                <Text>{empanadaOrders.length}</Text>
              </HStack>
            )}
          </HStack>
          <HStack spacing={4} mt={2}>
            <Button
              colorScheme="yellow"
              onClick={() => handleProductTypeChange("pizza")}
            >
              Pizza
            </Button>
            <Button
              colorScheme="yellow"
              onClick={() => handleProductTypeChange("empanada")}
            >
              Empanada
            </Button>
          </HStack>
        </VStack>
      </Center>

      {productType === "pizza" && (
        <>
          <Center>
            <Button
              onClick={addPizzaOrder}
              size={"lg"}
              padding={15}
              m={31}
              colorScheme="green"
            >
              + Agregar Pizza
            </Button>
          </Center>
          {pizzaOrders.length > 0 && (
            <Heading size="md" mt={4}>
              Pizzas ({pizzaOrders.length})
            </Heading>
          )}
          <Tabs
            index={selectedPizzaTab}
            onChange={(index) => setSelectedPizzaTab(index)}
          >
            <TabList>
              {pizzaOrders.map((pizza, index) => (
                <Tab key={index}>
                  <GiFullPizza /> {index + 1}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {pizzaOrders.map((pizza, index) => (
                <TabPanel
                  key={index}
                  ref={(el) => (pizzaRef.current[index] = el)}
                >
                  <Button
                    onClick={() => removePizzaOrder(index)}
                    colorScheme="red"
                  >
                    - Eliminar Pizza
                  </Button>
                  <Select
                    placeholder="Selecciona el tipo de pizza"
                    onChange={(e) =>
                      handlePizzaTypeChange(index, e.target.value)
                    }
                    mt={4}
                  >
                    <option value="muzzarela">Muzzarela</option>
                    <option value="soloSalsa">Solo Salsa</option>
                  </Select>
                  <CheckboxGroup
                    value={pizza.toppings}
                    onChange={(selectedToppings) =>
                      handlePizzaToppingsChange(index, selectedToppings)
                    }
                    mt={4}
                  >
                    <SimpleGrid
                      columns={{ base: 2, md: 4, lg: 6 }}
                      spacing={4}
                      padding={15}
                    >
                      {toppingOptions.map((topping) => (
                        <Checkbox key={topping} value={topping}>
                          {topping}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                    <Text mt={2} color="red.500">
                      {pizza.toppings.length > 3 &&
                        "Solo puedes seleccionar hasta 3 gustos."}
                    </Text>
                  </CheckboxGroup>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </>
      )}

      {productType === "empanada" && (
        <>
          <Center>
            <Button
              onClick={addEmpanadaOrder}
              size={"lg"}
              padding={15}
              m={31}
              colorScheme="green"
            >
              + Agregar Empanada
            </Button>
          </Center>
          {empanadaOrders.length > 0 && (
            <Heading size="md" mt={4}>
              Empanadas ({empanadaOrders.length})
            </Heading>
          )}
          <Box width="100vw" overflowX="auto">
            <Tabs
              isFitted
              index={selectedEmpanadaTab}
              onChange={(index) => setSelectedEmpanadaTab(index)}
            >
              <TabList>
                {empanadaOrders.map((_, index) => (
                  <Tab key={index}>
                    <EmpanadaIcon width={24} height={24} /> {index + 1}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {empanadaOrders.map((empanada, index) => (
                  <TabPanel
                    key={index}
                    ref={(el) => (empanadaRef.current[index] = el)}
                  >
                    <Button
                      onClick={() => removeEmpanadaOrder(index)}
                      colorScheme="red"
                    >
                      - Eliminar Empanada
                    </Button>
                    <RadioGroup
                      onChange={(value) => handleEmpanadaChange(index, value)}
                      value={empanada}
                    >
                      <SimpleGrid
                        columns={{ base: 2, md: 4, lg: 6 }}
                        spacing={4}
                        padding={15}
                      >
                        {empanadaOptions.map((option) => (
                          <Radio key={option} value={option}>
                            {option}
                          </Radio>
                        ))}
                      </SimpleGrid>
                    </RadioGroup>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Box>
        </>
      )}
      {(pizzaOrders.length > 0 || empanadaOrders.length > 0) && (
        <>
          <Button onClick={() => setShowDrinks(true)} mt={4}>
            Agregar Bebidas
          </Button>
          <Center>
            <VStack spacing={4}>
              {showDrinks && (
                <Box mt={4}>
                  <Heading size="md">Bebidas</Heading>
                  <Stack>
                    {drinks.map((drink, index) => (
                      <Box key={drink.name} display="flex" alignItems="center">
                        <Text mr={2}>{drink.name}</Text>
                        <NumberInput
                          defaultValue={drink.quantity}
                          min={0}
                          onChange={(value) =>
                            handleDrinkQuantityChange(index, parseInt(value))
                          }
                          maxW="100px"
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              )}
              <Button
                onClick={handleSubmit}
                color="white"
                backgroundColor="black"
                mt={4}
              >
                Enviar Pedido
              </Button>
            </VStack>
          </Center>
        </>
      )}
    </Box>
  );
};

export default OrderForm;
